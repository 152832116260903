import type { JSX } from 'solid-js';

type Props = Omit<JSX.SVGElementTags['svg'], 'width' | 'height'> & { class?: string };

export function Amex(props: Props) {
	return (
		<svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 -9 58 58">
			<rect width="57" height="39" x=".5" y=".5" fill="#006FCF" stroke="#F3F3F3" rx="3.5" />
			<path
				fill="#fff"
				fill-rule="evenodd"
				d="M11.9 28.9v-8.2h9.3l1 1.2 1-1.2h33.9v7.6s-1 .6-2 .6H36.5l-1.1-1.3v1.3h-3.7v-2.2s-.5.3-1.6.3h-1.3v1.9h-5.6l-1-1.3-1 1.3H12ZM1 14.5l2.1-4.6h3.6L8 12.4V10h4.5l.7 1.8.7-1.8h20.3v.9s1-1 2.8-1h6.5l1.2 2.6V10h3.8l1 1.4V10h3.8V18h-3.8l-1-1.5v1.5H43l-.6-1.3h-1.5l-.5 1.3h-3.8c-1.5 0-2.4-1-2.4-1v1h-5.7l-1.1-1.3v1.3H6.2l-.6-1.3H4.1l-.5 1.3H1v-3.6ZM1 17l2.8-6.2H6L8.8 17H7l-.5-1.3h-3l-.5 1.3H1Zm4.8-2.6-1-2.2-.8 2.2h1.8ZM9 17V11h2.6l1.5 4 1.5-4h2.6V17h-1.6v-4.5L13.8 17h-1.4l-1.7-4.5V17H9Zm9.4 0V11h5.3v1.4H20v1h3.6v1.3H20v1.1h3.7V17h-5.3Zm6.3 0V11h3.6c1.2 0 2.3.7 2.3 2a2 2 0 0 1-1.8 1.9L31 17h-2l-2-2.2h-.7V17h-1.6Zm3.5-4.7h-1.9v1.3h2c.3 0 .7-.3.7-.7 0-.3-.4-.6-.8-.6ZM33 17h-1.7V11H33V17Zm4 0h-.4c-1.8 0-2.8-1.2-2.8-3s1-3.1 3.3-3.1h1.8v1.4h-2c-.8 0-1.4.7-1.4 1.7 0 1.2.7 1.7 1.7 1.7h.5L37 17Zm.7 0 2.8-6.1h2.2l2.8 6.2h-1.9l-.5-1.3h-3l-.5 1.3h-1.9Zm4.8-2.5-1-2.2-.8 2.2h1.8Zm3.2 2.5V11h2.1l2.7 3.9v-4H52V17h-2l-2.7-4v4h-1.7ZM13 27.8v-6.1h5.4V23h-3.7v1h3.6v1.3h-3.6v1.1h3.7v1.3H13Zm26.3 0v-6.1h5.4V23h-3.8v1h3.6v1.3H41v1.1h3.8v1.3h-5.4Zm-20.7 0 2.6-3-2.7-3.1h2l1.7 1.9 1.6-2h2L23 24.9l2.6 3h-2L22 26l-1.5 2h-2Zm7.3 0v-6.1h3.7c1.5 0 2.3.9 2.3 2 0 1.5-1 2.2-2.6 2.2h-1.7v2H26Zm3.5-4.7h-1.8v1.4h1.8c.5 0 .9-.3.9-.7 0-.5-.4-.7-.9-.7Zm3.2 4.7v-6.1h3.7c1.2 0 2.3.7 2.3 2a2 2 0 0 1-1.8 1.9l2.2 2.2h-2l-2-2.2h-.7v2.2h-1.7Zm3.6-4.7h-2v1.3h2c.3 0 .7-.3.7-.7 0-.3-.3-.6-.7-.6Zm9.2 4.7v-1.3h3.3c.5 0 .7-.2.7-.5s-.2-.5-.7-.5h-1.5c-1.3 0-2-.8-2-1.9 0-1 .7-2 2.6-2H51l-.7 1.5h-2.8c-.5 0-.7.2-.7.5 0 .2.2.5.6.5H49c1.4 0 2 .8 2 1.8s-.6 2-2 2h-3.6Zm5.8 0v-1.3h3.3c.5 0 .7-.2.7-.5s-.2-.5-.7-.5H53c-1.3 0-2-.8-2-1.9 0-1 .7-2 2.6-2h3.2l-.7 1.5h-2.8c-.5 0-.7.2-.7.5 0 .2.2.5.6.5h1.6c1.4 0 2 .8 2 1.8s-.6 2-2 2h-3.6Z"
				clip-rule="evenodd"
			/>
		</svg>
	);
}

export function Discover(props: Props) {
	return (
		<svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 -9 58 58">
			<rect width="57" height="39" x=".5" y=".5" fill="#fff" stroke="#F3F3F3" rx="3.5" />
			<path
				fill="#1D1D1B"
				fill-rule="evenodd"
				d="M19 20.3c0 2.5 2 4.4 4.5 4.4.7 0 1.2-.2 2-.5v-2c-.7.7-1.2 1-2 1-1.6 0-2.8-1.2-2.8-2.9 0-1.6 1.2-2.8 2.8-2.8.7 0 1.3.2 2 .9v-2c-.7-.3-1.3-.4-2-.4-2.5 0-4.4 2-4.4 4.3Zm-4-2c0 .5.2.7 1.2 1 1.8.7 2.4 1.3 2.4 2.6 0 1.7-1.2 2.8-3 2.8-1.1 0-2-.5-2.8-1.6l1-1a2 2 0 0 0 1.8 1c.8 0 1.3-.4 1.3-1a1 1 0 0 0-.5-1l-1.1-.4c-1.5-.6-2-1.1-2-2.3 0-1.4 1-2.4 2.5-2.4 1 0 1.8.3 2.5 1l-.9 1c-.4-.4-.8-.6-1.3-.6-.7 0-1.2.4-1.2.9Zm-4.4 6.2h1.6v-8.3h-1.6v8.3Zm-3.3-2.1c-.5.5-1.2.7-2.2.7h-.5v-5.5h.5c1 0 1.6.2 2.2.6.5.5.9 1.3.9 2.1s-.4 1.6-1 2.1Zm-2-6.2H3v8.3h2.4c1.2 0 2.1-.3 3-1 .9-.8 1.4-2 1.4-3.2 0-2.4-1.8-4.1-4.4-4.1Zm30.8 0 2.2 5.5 2.3-5.5h1.7l-3.6 8.5H38l-3.6-8.5h1.8Zm7 8.3h4.6V23h-3v-2.3h2.9v-1.4h-3v-1.8h3v-1.4h-4.5v8.3Zm7.3-4.5h.4c1 0 1.6-.5 1.6-1.3s-.5-1.2-1.5-1.2h-.5V20Zm.7-3.8c2 0 3 .9 3 2.4 0 1.3-.7 2.1-2 2.4l2.7 3.5h-2L50.6 21h-.2v3.4h-1.7v-8.3h2.4Z"
				clip-rule="evenodd"
			/>
			<path
				fill="url(#a)"
				fill-rule="evenodd"
				d="M34.2 22.7c1.3-2 .7-4.8-1.4-6-2-1.4-4.8-.8-6 1.3-1.4 2-.8 4.8 1.3 6 2 1.4 4.7.8 6-1.3Z"
				clip-rule="evenodd"
			/>
			<defs>
				<linearGradient id="a" x1="36.5" x2="29.1" y1="19" y2="14.2" gradientUnits="userSpaceOnUse">
					<stop stop-color="#F6A000" />
					<stop offset=".6" stop-color="#E47E02" />
					<stop offset="1" stop-color="#D36002" />
				</linearGradient>
			</defs>
		</svg>
	);
}

export function Mastercard(props: Props) {
	return (
		<svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 -9 58 58">
			<rect width="57" height="39" x=".5" y=".5" fill="#fff" stroke="#F3F3F3" rx="3.5" />
			<path
				fill="#231F20"
				fill-rule="evenodd"
				d="M21.2 30.9v2.9h-.6v-.3c-.2.3-.5.4-1 .4-.8 0-1.4-.6-1.4-1.5 0-1 .6-1.6 1.5-1.6.4 0 .7.2.9.4V31h.6Zm-1.5.5c-.5 0-.9.4-.9 1 0 .5.4 1 1 1 .5 0 .8-.5.8-1 0-.6-.3-1-.9-1Zm23.4 1c0-.6.4-1 1-1 .5 0 .8.4.8 1 0 .5-.3 1-.9 1-.5 0-.9-.5-.9-1Zm2.4-2.7v4.1H45v-.3c-.2.3-.5.4-1 .4-.8 0-1.4-.6-1.4-1.5 0-1 .6-1.6 1.4-1.6.5 0 .8.2 1 .4v-1.5h.6Zm-16.2 1.7c.4 0 .7.3.7.7h-1.5c0-.4.3-.7.8-.7Zm-1.5 1c0-1 .6-1.6 1.5-1.6.8 0 1.4.6 1.4 1.6v.2h-2.2c0 .5.5.7.9.7.3 0 .6 0 .8-.3l.4.5c-.4.3-.8.4-1.3.4-.9 0-1.5-.6-1.5-1.5Zm9.3 0c0-.6.4-1 1-1 .5 0 .8.4.8 1 0 .5-.3 1-.9 1-.5 0-.9-.5-.9-1Zm2.4-1.5v2.9H39v-.3c-.2.3-.5.4-1 .4-.8 0-1.5-.6-1.5-1.5 0-1 .7-1.6 1.5-1.6.5 0 .8.2 1 .4V31h.6Zm-6 1.5c0 .9.6 1.5 1.5 1.5.5 0 .8 0 1.1-.3l-.3-.6c-.2.2-.5.3-.8.3-.5 0-.9-.4-.9-1 0-.5.4-.9 1-.9.2 0 .5.1.7.3l.3-.5c-.3-.3-.6-.4-1-.4-1 0-1.6.7-1.6 1.6Zm7.5-1.2c.2-.2.4-.4.8-.4l.5.1-.2.6a1 1 0 0 0-.4 0c-.5 0-.7.2-.7.7v1.6h-.6v-3h.6v.4Zm-16.5 0c-.4-.3-.8-.4-1.3-.4-.7 0-1.2.4-1.2 1 0 .4.4.7 1 .8h.3c.4.1.6.2.6.4s-.3.3-.7.3c-.5 0-.8-.1-1-.3l-.3.5c.3.3.8.4 1.3.4.8 0 1.3-.4 1.3-1 0-.5-.4-.7-1-.8h-.3c-.3-.1-.5-.2-.5-.4s.2-.3.5-.3l1 .2.3-.5Zm7.5 0c.2-.2.4-.4.8-.4l.5.1-.2.6a1 1 0 0 0-.4 0c-.5 0-.7.2-.7.7v1.6h-.6v-3h.6v.4Zm-4.7-.3h-1v-1h-.7v1H25v.6h.6v1.3c0 .7.2 1.1 1 1.1.3 0 .6 0 .8-.2l-.2-.6c-.2.2-.4.2-.6.2-.3 0-.4-.2-.4-.5v-1.3h1v-.6Zm-9.7 1v2h-.7v-1.7c0-.5-.2-.8-.6-.8-.4 0-.7.3-.7.8v1.6h-.7v-1.6c0-.5-.2-.8-.6-.8-.5 0-.8.3-.8.8v1.6H13v-3h.6v.5c.3-.4.6-.5.9-.5.5 0 .8.2 1 .5.3-.4.6-.5 1-.5.8 0 1.2.5 1.2 1.2Z"
				clip-rule="evenodd"
			/>
			<path fill="#FF5F00" d="M34 25.9h-9.8V8.4H34v17.5Z" />
			<path
				fill="#EB001B"
				d="M24.9 17.1A11 11 0 0 1 29 8.4 11.2 11.2 0 0 0 11 17 11.2 11.2 0 0 0 29.1 26 11 11 0 0 1 25 17"
			/>
			<path fill="#F79E1B" d="M47.3 17.1A11.2 11.2 0 0 1 29 26a11 11 0 0 0 0-17.5A11.2 11.2 0 0 1 47.3 17" />
		</svg>
	);
}
export function Visa(props: Props) {
	return (
		<svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 -11 70 70">
			<rect width="69" height="47" x=".5" y=".5" fill="#fff" stroke="#D9D9D9" rx="5.5" />
			<path
				fill="#172B85"
				fill-rule="evenodd"
				d="M21.3 32.5H17l-3.2-12.1a2 2 0 0 0-1-1.3c-1-.6-2.4-1-3.8-1.3v-.5h6.8c1 0 1.7.8 1.8 1.6l1.6 8.7 4.3-10.3h4.1l-6.3 15.2Zm8.7 0h-4l3.3-15.2h4L30 32.5Zm8.4-11c.2-.8.9-1.2 1.7-1.2 1.3-.1 2.7 0 3.9.7l.7-3.3c-1.2-.5-2.5-.7-3.7-.7-3.8 0-6.7 2.1-6.7 5 0 2.2 2 3.4 3.4 4.1 1.6.7 2.2 1.2 2 1.9 0 1-1.1 1.5-2.3 1.5-1.4 0-2.8-.4-4.1-1l-.7 3.3c1.4.6 2.9.8 4.3.8 4.4.1 7-2 7-5.1 0-4-5.5-4.2-5.5-6Zm19.6 11-3.2-15.2h-3.4c-.7 0-1.4.5-1.6 1.2l-6 14H48l.8-2.2h5l.5 2.2H58Zm-6-11 1.2 5.7h-3.3l2-5.8Z"
				clip-rule="evenodd"
			/>
		</svg>
	);
}
