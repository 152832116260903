import { IconCreditCard01 } from '@troon/icons/credit-card-01';
import { CreditCardBrand } from '../graphql';
import { Amex, Discover, Mastercard, Visa } from '../components/credit-cards';
import type { ComponentProps } from 'solid-js';

export const cardBrandToString: Record<CreditCardBrand, string> = {
	[CreditCardBrand.Amex]: 'American Express',
	[CreditCardBrand.Discover]: 'Discover',
	[CreditCardBrand.Mastercard]: 'Mastercard',
	[CreditCardBrand.Other]: 'Other',
	[CreditCardBrand.Visa]: 'Visa',
};
export const cardBrandToComponent: Record<CreditCardBrand, typeof Amex> = {
	[CreditCardBrand.Amex]: Amex,
	[CreditCardBrand.Discover]: Discover,
	[CreditCardBrand.Mastercard]: Mastercard,
	[CreditCardBrand.Other]: (props: ComponentProps<typeof Amex>) => <IconCreditCard01 {...props} />,
	[CreditCardBrand.Visa]: Visa,
};
